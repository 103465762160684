$dark1: #c1c2c5;
$dark2: #909296;
$dark3: #5c5f66;
$dark4: #373a40;
$dark5: #2c2e33;
$dark6: #25262b;
$dark7: #1a1b1e;
$dark8: #141517;
$dark9: #101113;

$yellow0: #fff9db;
$yellow1: #fff3bf;
$yellow2: #ffec99;
$yellow3: #ffe066;
$yellow4: #ffd43b;
$yellow5: #fcc419;
$yellow6: #fab005;
$yellow7: #f59f00;
$yellow8: #f08c00;
$yellow9: #e67700;

$background: $yellow0;
$background-dark: $yellow7;
