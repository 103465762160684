//for use we need to access $dark1 like colors.$dark1 @use "./colors";
//error Undefined variable. ╷ 8 │ color: $dark9;

//for import  we need to access $dark1 like $dark1 @use "./colors";

@use "./colors" as *;
//as * will resolve this error error Undefined variable. ╷ 8 │ color: $dark9;

.light {
  .text {
    color: $dark9;
  }

  .background {
    background-color: $dark1;
  }
}

.dark {
  .text {
    color: $yellow0;
  }
  .background {
    background-color: $yellow9;
  }
}
