body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* @font-face {
  font-family: "NunitoSansBlack";
  src: local("NunitoSansBlack"),
    url("./assets/fonts/Nunito_Sans/NunitoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansExtraBold";
  src: local("NunitoSansExtraBold"),
    url("./assets/fonts/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansRegular";
  src: local("NunitoSansRegular"),
    url("./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
} */

/* @font-face {
  font-family: "NunitoSansExtraBold";
  src: local("NunitoSansExtraBold"),
    url("./assets/fonts/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf"),
    format("truetype");
}

@font-face {
  font-family: "NunitoSansRegular";
  src: local("NunitoSansRegular"),
    url("./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf"), format("truetype");
} */
