@use "../../../theme/scss/colors" as *;

.dashboard_container {
  // display: flex;
  // flex: 1;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  height: 100vh;
  width: 100%;
}

.light {
  background-color: $yellow0;
}

.dark {
  background-color: $yellow9;
}
