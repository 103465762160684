.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.box {
  position: relative;
  width: 200px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
}

.box::before,
.box::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.box::before {
  top: -1px;
  left: -1px;
  transform: rotate(45deg);
}

.box::after {
  bottom: -1px;
  right: -1px;
  transform: rotate(-45deg);
}

.mic-button-bot {
  background-color: transparent;
  border: 0px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: rgb(74, 74, 74);
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px;
  position: absolute;
  right: 8vw;
  top: 79vh;
  z-index: 10;
}


.mic-image-bot {
  content: "";
  position: absolute;
  width: 2.5vw;
  height: 5.5vh;
  border-radius: 20%;
  z-index: 11;
  margin-right: 100px; 
  transition: fill 0.3s;
}


.mic-button-bot2 {
  background-color: transparent;
  border: 0px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: rgb(74, 74, 74);
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px;
  position: absolute;
  right: 5.3vw; 
  top: 65vh; 
  top: 65vh; 
  z-index: 10;
}


.mic-image-bot2 {
  content: "";
  position: absolute;
  width: 2.1vw;
  height: 4.7vh;
  height: 4.7vh;
  border-radius: 20%;
  z-index: 11;
  margin-right: 100px; 
  transition: fill 0.3s; 
}


 .typingIndicator {
  display: flex;
  align-items: center;
  gap: 8px;
}

.typingIndicator .typingText {
  color: gray;
  animation: bounce 1s infinite ease; 
}

.typingIndicator .dot {
  width: 10px;
  height: 10px;
  background-color: #CCE6FF;
  border-radius: 50%;
  animation: bounce 1s infinite ease;
}

.typingIndicator .dot:nth-of-type(1) {
  animation-delay: 0s;
}

.typingIndicator .dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.typingIndicator .dot:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.scrollableTable {
  width: 100%; 
  height: 100%; 
  overflow: scroll;
  border-collapse: collapse;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.scrollableTable thead th{
  border: 2px solid #ddd;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  background-color: #CCE6FF;
}   
.scrollableTable tbody td {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
  font-size: 12px;
}

.scrollableTable thead {
  background-color: #f4f4f4;
}
