@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.react-chatbot-kit-chat-container {
  width: 100%;
  height: 100%;
  margin: auto;
}

.react-chatbot-kit-chat-inner-container {
  height:56vh;
}

.react-chatbot-kit-chat-header {
  /* background: linear-gradient(226.5deg, #30D1B4 6.32%, #0B4DBC 53.82%, #0B4DBC 91.13%); */
  /* background: linear-gradient(179.98deg, #DE4496 38.71%, #B14213 115.47%); */
  background: linear-gradient(202.64deg, #e24a95 21.95%, #4a2ba3 92.07%);
  color: aliceblue;
  font-size: 0.95em;
}

/* .react-chatbot-kit-chat-input-container {
  position: inherit;
  margin-top: -23%;
} */
.react-chatbot-kit-chat-input-container {
  position: relative; /* Instead of 'inherit' */
  margin-top: -5%; /* Use percentage */
  background-color: white;
}

.react-chatbot-kit-chat-message-container {
  /* height: 635px; */
  height: 62.4vh;
  overflow-x: hidden; 
  background-color: white;
}

.react-chatbot-kit-chat-input {
  border: 1px solid #d1d1d1;
  border-radius: 10px; /* Adjust the value to control the curvature */
  /* margin-right: 0.5%; */
  /* margin-right: 2%; */
  /* margin-left: 18px;
  height: 58px; */
}

/* .react-chatbot-kit-chat-input-form { */
  /* margin-top: 18%; */
  /* margin-top: 313px; */
  /* background-color: #F2F5FD;  */
  /* height: "10px"; */
  /* margin-left:-8px; */
/* } */
.react-chatbot-kit-chat-input-form {
  display: flex;
  justify-content: space-between;
  /* margin-top: 10vh;  */
}
.react-chatbot-kit-chat- input::placeholder {
}

/* .react-chatbot-kit-chat-btn-send {
  /* width: 8%;
  /* margin-right: 15px;   */
  /* border-radius: 10px; */
  /* background: linear-gradient(90deg, #5eb0fa 0%, #1157cd 50.54%, #9c90fa 100%); 
  width: 5.8%;
  margin-left: 58px;
  border-radius: 10px; */
/* } */ 
.react-chatbot-kit-chat-btn-send {
  width: 5vw; /* Relative to viewport width */
  margin-left: 3vw;
  border-radius: 10px;
  background: linear-gradient(90deg, #5eb0fa 0%, #1157cd 50.54%, #9c90fa 100%); 
}


.react-chatbot-kit-chat-btn-send-icon {
}

.react-chatbot-kit-user-chat-message {
  margin-right: 10px;
  margin-left: 10px;
  width:40%;
  display: flex;
  align-items: center;
  font-size: 13px; /* Increase the font size as needed */
  font-family: "Nunito Sans";
}

.react-chatbot-kit-chat-bot-message-container {
}

.react-chatbot-kit-chat-bot-avatar-container {
  background-image: url("../../../assets/images/Logo Assets/LogoMark1.png");
}

.react-chatbot-kit-chat-bot-avatar-icon {
  background-image: url("../../../assets/images/Logo Assets/LogoMark1.png");
}

.react-chatbot-kit-chat-bot-avatar-letter {
  background-image: url("../../../assets/images/Logo Assets/LogoMark1.png");
}

.react-chatbot-kit-chat-bot-message {
  /* margin:0;
  background: linear-gradient(90deg, #5EB0FA 0%, #1157CD 51.04%, #9C90FA 100%);
  margin-left: 5px;
  width: 40%; Adjust the width as needed */

  margin: 0;
  /* background: linear-gradient(90deg, #5eb0fa 0%, #1157cd 51.04%, #9c90fa 100%); */
  background-color: #f1f1f1;
  color: #000;
  margin-left: 5px;
  width: 40%; /* Adjust the width as needed */
  display: flex;
  align-items: center;
  font-size: 13px; /* Increase the font size as needed */
  font-family: "Nunito Sans";
}

.react-chatbot-kit-chat-bot-message-arrow {
  /* background: linear-gradient(90deg, #5eb0fa 0%, #1157cd 50.54%, #9c90fa 100%); */
  background: #f1f1f1;
  margin-left: -1px;
  margin-top: 10px;
  border-right:#f1f1f1
  /* color: black; */
}

.react-chatbot-kit-user-avatar-container {
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}
@media only screen and (max-width: 600px) {
  .react-chatbot-kit-chat-input-container {
    margin-top: 10%; /* Increased for smaller screens */
  }

  .react-chatbot-kit-chat-btn-send {
    width: 15vw; /* Larger button for smaller screens */
  }

}

.parent-container {
  position: relative;
  height: 87vh;
}

.end-chat-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10; 
}


